/**
 * Safari does not support 'narrowSymbol' and will throw an exception
 */
const symbolStyle = (() => {
	try {
		new Intl.NumberFormat([], {
			style: 'currency',
			currency: 'usd',
			currencyDisplay: 'narrowSymbol'
		}).format(1.512);

		return 'narrowSymbol';
	} catch (ex) {
		return 'symbol';
	}
})();

export function formatSiacoinString(v, sign = 'always') {
	const formatted = new Intl.NumberFormat([], {
		signDisplay: sign,
		minimumFractionDigits: 2,
		maximumFractionDigits: 4
	}).format(v.div('1e24').toFixed(4));

	return `${formatted} SC`;
}

export function formatCurrencyString(v, rate = 0.015, code = 'usd', sign = 'always', maxDigits = 4) {
	const value = v.div('1e24').times(rate).toFixed(4);
	let formatted;

	switch (code) {
	case 'btc':
		formatted = new Intl.NumberFormat([], {
			signDisplay: sign,
			minimumFractionDigits: 2,
			maximumFractionDigits: maxDigits
		}).format(value);
		return `${formatted} ${code.toUpperCase()}`;
	default:
		return new Intl.NumberFormat([], {
			signDisplay: sign,
			style: 'currency',
			currency: code,
			currencyDisplay: symbolStyle,
			minimumFractionDigits: 2,
			maximumFractionDigits: maxDigits
		}).format(value);
	}
}

export function formatExchangeRate(rate, code = 'usd', sign = 'always') {
	switch (code.toLowerCase()) {
	case 'btc':
		return new Intl.NumberFormat([], {
			signDisplay: sign,
			minimumFractionDigits: 0,
			maximumFractionDigits: 0
		}).format(rate * 100000000) + ' SAT';
	default:
		return new Intl.NumberFormat([], {
			signDisplay: sign,
			style: 'currency',
			currency: code,
			currencyDisplay: symbolStyle,
			minimumFractionDigits: 2,
			maximumFractionDigits: 4
		}).format(rate);
	}
}